import { Col, Row } from "react-bootstrap";
import {
  MDBBtn,
  MDBSpinner 
} from "mdb-react-ui-kit";

import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
  } from 'mdb-react-ui-kit';
import { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faForward } from "@fortawesome/free-solid-svg-icons";
import { faForwardStep } from "@fortawesome/free-solid-svg-icons/faForwardStep";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import ReactGA from "react-ga4";



const GetStart = () => {
    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [basicModal, setBasicModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        ReactGA.initialize("G-PB8W8W89QW");
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname + window.location.search,
          title: "Contatct us Page"
        });
    
      
    
      }, []);

    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        try {
            //const response = await axios.post('https://cont3actauto5m345et3845792347.autometaco.com:443/send-email'
            const response = await axios.post('https://cont3actauto5m345et3845792347.autometaco.com:443/send-email', {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone
            }); 
            
            if(response.status === 200) {
                setBasicModal(true);
                setIsLoading(false);
                setTimeout(() => {
                    setBasicModal(false);
                }, 5000);
                setFirstName('')
                setLastName('')
                setEmail('')
                setPhone('')
            }
            
        } catch (error) {
            console.error('Error sending email:', error);
            setIsLoading(false);
            setErrorModal(true)
        }
      };

  return (
    <div className="getstart">
      <Navbar className="bg-body-tertiary px-4">
        <Navbar.Brand href="#">
          <Link to={"/"}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ marginRight: "5px" }}
            />
            Back to home
          </Link>
        </Navbar.Brand>
      </Navbar>
      <div className="container">
        <div className="row">
          <Col lg={6} md={6}>
            <Row>
              <h4>We Are Happy to contact with you</h4>
            </Row>
            <Row>
            <form onSubmit={handleSubmit}>
              <Col lg={12} md={12}>
                <input
                required
                  className="mt-3"
                  placeholder="Frist name"
                  value={firstName}
                  onChange={(e)=> setFirstName(e.target.value)}
                  id="typeEmail"
                />
              </Col>
              <Col lg={12}>
                <input
                required
                  className="mt-3 "
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e)=> setLastName(e.target.value)}
                  id="typeText"
                />
              </Col>
              <Col lg={12}>
                <input
                required
                  className="mt-3"
                  placeholder="Email"
                  value={email}
                  onChange={(e)=> setEmail(e.target.value)}
                  id="typeEmail"
                  type="email"
                />
              </Col>
              <Col lg={12}>
                <input
                required
                  className="mt-3"
                  placeholder="Your phone number"
                  value={phone}
                  onChange={(e)=> setPhone(e.target.value)}
                  id="typeNumber"
                  type="number"
                />
              </Col>
              <Col lg={12} md={12} className="justify-content-center d-flex">
                <MDBBtn type="submit" color="light" className="justify-content-end mt-1 mt-3">
                {isLoading ? <MDBSpinner grow role="status" className="me-2" /> : null} {/* إضافة spinner إذا كان isLoading مفعّلاً */}
                {isLoading? '' :'Contact us'}
                </MDBBtn>
              </Col>
              </form>
            </Row>
          </Col>
          <Col lg={6} md={6} className="d-none d-lg-block d-md-block">
            <h1>Technology Solutions for Smarter Future</h1>
          </Col>
          <Col className="d-md-none">
            <div className="animdiv">
              <h1 className="anim">Technology Solutions for Smarter Future</h1>
            </div>
          </Col>
        </div>

        <Row className="row4 ">
          <Col
            lg={4}
            md={3}
            xs={12}
            className="d-none d-lg-block d-md-block wow animate__fadeInDownBig powered mt-lg-3 mt-md-3"
            data-wow-offset="100"
            data-wow-duration="2s"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="165.45"
              height="27.174"
              viewBox="0 0 165.45 27.174"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_194"
                    data-name="Rectangle 194"
                    width="27.174"
                    height="27.174"
                    fill="#fff"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_32"
                data-name="Group 32"
                transform="translate(-235 -5915.542)"
              >
                <g
                  id="Group_38"
                  data-name="Group 38"
                  transform="translate(-26.27 5190.972)"
                >
                  <text
                    id="Powered_by_AutoMeta"
                    data-name="Powered by AutoMeta"
                    transform="translate(298.72 742.883)"
                    fill="#fff"
                    font-size="13"
                    font-family="SegoeUI, Segoe UI"
                  >
                    <tspan x="0" y="0">
                      Powered by AutoMeta
                    </tspan>
                  </text>
                  <g
                    id="Group_15"
                    data-name="Group 15"
                    transform="translate(261.27 724.57)"
                  >
                    <g
                      id="Group_14"
                      data-name="Group 14"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M96.992,150.735a6.57,6.57,0,0,1-5.611-1.317c-.061-.05-.122-.1-.185-.145a6.018,6.018,0,0,1-1.256-1.261,5.828,5.828,0,0,1-.95-1.962A6.1,6.1,0,0,1,89,143.276c.037-.208.425-2.345.912-2.335l.017,0c.423.1.64,1.406.726,1.84a7.7,7.7,0,0,0,1.869,3.466,5.664,5.664,0,0,0,1.259.955,3.914,3.914,0,0,0,1.369.541,4.724,4.724,0,0,0,1.2.009c.287-.021.561-.064,1.109-.151a5.014,5.014,0,0,0,.8-.164,3.973,3.973,0,0,0,.717-.314,4.111,4.111,0,0,0,.531-.354c.336-.259,1.853-1.36,2.156-1.053.288.292-.472,1.827-.584,2.04a6.626,6.626,0,0,1-4.09,2.977"
                        transform="translate(-78.389 -124.373)"
                        fill="#fff"
                      />
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M45.428,132.557a6.571,6.571,0,0,1-3.036-4.9c-.008-.078-.016-.156-.028-.234a6.015,6.015,0,0,1,0-1.78,5.83,5.83,0,0,1,.715-2.059,6.1,6.1,0,0,1,1.969-1.954c.173-.121,1.959-1.358,2.3-1.007a.074.074,0,0,1,.01.013c.229.369-.541,1.447-.788,1.815a7.7,7.7,0,0,0-1.129,3.772,5.664,5.664,0,0,0,.215,1.565,3.915,3.915,0,0,0,.585,1.351,4.738,4.738,0,0,0,.843.856c.218.188.442.351.891.677a5.006,5.006,0,0,0,.682.449,3.96,3.96,0,0,0,.729.285,4.1,4.1,0,0,0,.625.125c.421.054,2.272.349,2.269.78,0,.41-1.625.958-1.855,1.029a6.625,6.625,0,0,1-5-.787"
                        transform="translate(-37.327 -106.391)"
                        fill="#fff"
                      />
                      <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M5.692,96.992a6.57,6.57,0,0,1,1.317-5.611c.05-.061.1-.122.145-.185A6.017,6.017,0,0,1,8.415,89.94a5.828,5.828,0,0,1,1.962-.95A6.1,6.1,0,0,1,13.151,89c.208.037,2.345.425,2.335.912a.075.075,0,0,1,0,.017c-.1.423-1.406.64-1.84.726a7.7,7.7,0,0,0-3.466,1.869,5.664,5.664,0,0,0-.955,1.259,3.913,3.913,0,0,0-.541,1.369,4.725,4.725,0,0,0-.009,1.2c.021.287.064.561.151,1.109a5.016,5.016,0,0,0,.164.8,3.975,3.975,0,0,0,.314.717,4.108,4.108,0,0,0,.354.531c.259.336,1.36,1.853,1.053,2.156-.292.288-1.827-.472-2.04-.584a6.625,6.625,0,0,1-2.977-4.09"
                        transform="translate(-4.879 -78.389)"
                        fill="#fff"
                      />
                      <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M1.008,45.428a6.571,6.571,0,0,1,4.9-3.036c.078-.008.156-.016.234-.028a6.015,6.015,0,0,1,1.78,0,5.83,5.83,0,0,1,2.059.715,6.1,6.1,0,0,1,1.954,1.969c.121.173,1.358,1.959,1.007,2.3l-.013.01c-.369.229-1.447-.541-1.815-.788A7.7,7.7,0,0,0,7.34,45.441a5.664,5.664,0,0,0-1.565.215,3.915,3.915,0,0,0-1.351.585,4.737,4.737,0,0,0-.856.843c-.188.218-.351.442-.677.891a5.006,5.006,0,0,0-.45.682,3.96,3.96,0,0,0-.285.729,4.1,4.1,0,0,0-.125.625c-.054.421-.349,2.272-.78,2.269-.41,0-.958-1.625-1.029-1.855a6.626,6.626,0,0,1,.787-5"
                        transform="translate(0 -37.327)"
                        fill="#fff"
                      />
                      <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M37.345,5.692a6.57,6.57,0,0,1,5.611,1.317c.061.05.122.1.185.145A6.017,6.017,0,0,1,44.4,8.415a5.829,5.829,0,0,1,.95,1.962,6.1,6.1,0,0,1-.011,2.774c-.037.208-.425,2.345-.912,2.335l-.017,0c-.423-.1-.64-1.406-.726-1.84a7.7,7.7,0,0,0-1.869-3.466,5.664,5.664,0,0,0-1.259-.955,3.914,3.914,0,0,0-1.369-.541,4.725,4.725,0,0,0-1.2-.009c-.287.021-.561.064-1.109.151a5.016,5.016,0,0,0-.8.164,3.974,3.974,0,0,0-.717.314,4.107,4.107,0,0,0-.531.354c-.336.259-1.853,1.36-2.156,1.053-.288-.292.472-1.827.584-2.04a6.625,6.625,0,0,1,4.09-2.977"
                        transform="translate(-28.774 -4.879)"
                        fill="#fff"
                      />
                      <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M110.816,1.008a6.57,6.57,0,0,1,3.036,4.9c.008.078.016.156.028.234a6.015,6.015,0,0,1,0,1.78,5.83,5.83,0,0,1-.715,2.059,6.1,6.1,0,0,1-1.969,1.954c-.173.121-1.959,1.358-2.3,1.007a.073.073,0,0,1-.01-.013c-.229-.369.541-1.447.788-1.815A7.7,7.7,0,0,0,110.8,7.34a5.664,5.664,0,0,0-.215-1.565A3.915,3.915,0,0,0,110,4.423a4.737,4.737,0,0,0-.843-.856c-.218-.188-.442-.351-.891-.677a5.006,5.006,0,0,0-.682-.45,3.959,3.959,0,0,0-.729-.285,4.1,4.1,0,0,0-.625-.125c-.421-.054-2.272-.349-2.269-.78,0-.41,1.625-.958,1.855-1.029a6.626,6.626,0,0,1,5,.787"
                        transform="translate(-91.742 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M150.734,37.344a6.57,6.57,0,0,1-1.317,5.611c-.05.061-.1.122-.145.185a6.017,6.017,0,0,1-1.261,1.256,5.829,5.829,0,0,1-1.962.95,6.1,6.1,0,0,1-2.774-.011c-.208-.037-2.345-.425-2.335-.912a.075.075,0,0,1,0-.017c.1-.423,1.406-.64,1.84-.726a7.7,7.7,0,0,0,3.466-1.869,5.663,5.663,0,0,0,.955-1.259,3.913,3.913,0,0,0,.541-1.369,4.726,4.726,0,0,0,.009-1.2c-.021-.287-.064-.561-.151-1.109a5.016,5.016,0,0,0-.164-.8,3.975,3.975,0,0,0-.314-.717,4.1,4.1,0,0,0-.354-.531c-.259-.336-1.36-1.853-1.053-2.156.292-.288,1.827.472,2.04.584a6.625,6.625,0,0,1,2.977,4.09"
                        transform="translate(-124.373 -28.773)"
                        fill="#fff"
                      />
                      <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M132.557,110.816a6.571,6.571,0,0,1-4.9,3.036c-.078.008-.156.016-.234.028a6.011,6.011,0,0,1-1.78,0,5.83,5.83,0,0,1-2.059-.715,6.1,6.1,0,0,1-1.954-1.969c-.121-.173-1.358-1.959-1.007-2.3l.013-.01c.369-.229,1.447.542,1.815.788a7.7,7.7,0,0,0,3.772,1.129,5.669,5.669,0,0,0,1.565-.215,3.917,3.917,0,0,0,1.351-.586,4.732,4.732,0,0,0,.856-.843c.188-.218.351-.442.677-.891a5.013,5.013,0,0,0,.449-.682,3.954,3.954,0,0,0,.285-.729,4.1,4.1,0,0,0,.125-.625c.054-.421.349-2.272.78-2.269.41,0,.958,1.625,1.029,1.855a6.625,6.625,0,0,1-.787,5"
                        transform="translate(-106.391 -91.742)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </Col>
          <Col lg={4} md={4} className="d-none d-md-block ">
            <p style={{ fontSize: "14px" }}>
              Experience the incredible possibilities of technology <br></br>
              with Autometa, where creativity and exceptionalquality come
              together.
            </p>
          </Col>

          <Col
            lg={4}
            md={5}
            xs={12}
            className="text-center d-flex d-lg-block d-md-block justify-content-center"
          >
            <Row>
              <Col
                lg={2}
                md={2}
                xs={2}
                className="wow animate__fadeInDownBig mx-sm-2 mx-lg-0 mx-md-0"
                data-wow-offset="50"
                data-wow-duration="1s"
              >
              <a  href="https://www.facebook.com/profile.php?id=61558817014587&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                >
                  <g
                    id="Group_66"
                    data-name="Group 66"
                    transform="translate(-1479 -6793)"
                  >
                    <circle
                      id="Ellipse_21"
                      data-name="Ellipse 21"
                      cx="23"
                      cy="23"
                      r="23"
                      transform="translate(1479 6793)"
                      fill="#f0f3f7"
                    />
                    <g id="Facebook" transform="translate(1490 6804.5)">
                      <g
                        id="Facebook-2"
                        data-name="Facebook"
                        transform="translate(6 2)"
                      >
                        <path
                          id="Path"
                          d="M7.325,0A4.625,4.625,0,0,0,2.7,4.625V7.2H.225A.225.225,0,0,0,0,7.425v3.4a.225.225,0,0,0,.225.225H2.7v6.975a.225.225,0,0,0,.225.225h3.4a.225.225,0,0,0,.225-.225V11.05h2.5a.225.225,0,0,0,.218-.17l.85-3.4A.225.225,0,0,0,9.9,7.2H6.55V4.625a.775.775,0,0,1,.775-.775h2.6a.225.225,0,0,0,.225-.225V.225A.225.225,0,0,0,9.925,0Z"
                          transform="translate(0.875 0.875)"
                          fill="#0b0b0b"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                </a>
              </Col>

              <Col
                lg={2}
                md={2}
                xs={2}
                className="wow animate__fadeInDownBig mx-2"
                data-wow-offset="50"
                data-wow-duration="1s"
              >
              <a href="https://x.com/Autometac?t=8ebqKOA4V8Sw2lbpG8Nj8Q&s=09" target="_blank" rel="noreferrer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                >
                  <g
                    id="Group_67"
                    data-name="Group 67"
                    transform="translate(-1547 -6793)"
                  >
                    <circle
                      id="Ellipse_20"
                      data-name="Ellipse 20"
                      cx="23"
                      cy="23"
                      r="23"
                      transform="translate(1547 6793)"
                      fill="#f0f3f7"
                    />
                    <g id="Twitter" transform="translate(1558 6804.5)">
                      <g
                        id="Twitter-2"
                        data-name="Twitter"
                        transform="translate(2 4)"
                      >
                        <path
                          id="Path"
                          d="M19.486,2.227a.1.1,0,0,0-.12-.149,8.49,8.49,0,0,1-2.049.512,4.13,4.13,0,0,0,1.72-2.02A.1.1,0,0,0,18.9.453a8.21,8.21,0,0,1-2.325.857.1.1,0,0,1-.093-.03A4.1,4.1,0,0,0,9.488,4.93a.1.1,0,0,1-.1.123A11.64,11.64,0,0,1,4.835,3.814,11.64,11.64,0,0,1,1.159.883.1.1,0,0,0,1,.9a4.16,4.16,0,0,0-.48,1.942,4.09,4.09,0,0,0,1.82,3.41,4.051,4.051,0,0,1-1.709-.43.1.1,0,0,0-.147.091,4.231,4.231,0,0,0,.933,2.467A4.1,4.1,0,0,0,3.777,9.791a3.929,3.929,0,0,1-1.1.17,4.9,4.9,0,0,1-.606-.045.1.1,0,0,0-.109.131,4.11,4.11,0,0,0,3.776,2.684,8.22,8.22,0,0,1-5.06,1.76q-.283,0-.565-.018a.1.1,0,0,0-.063.188,11.57,11.57,0,0,0,5.919,1.62A11.59,11.59,0,0,0,17.677,4.6v-.48a.1.1,0,0,1,.04-.08A8.429,8.429,0,0,0,19.486,2.227Z"
                          transform="translate(0.323 0)"
                          fill="#0b0b0b"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                </a>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={2}
                className="wow animate__fadeInDownBig mx-sm-2 mx-lg-0 mx-md-0"
                data-wow-offset="50"
                data-wow-duration="1s"
              >
              <a  href="https://www.linkedin.com/company/autometac/about/?viewAsMember=true" target="_blank" rel="noreferrer">
                <svg 
               
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                >
                  <g
                    id="Group_68"
                    data-name="Group 68"
                    transform="translate(-1615 -6793)"
                  >
                    <circle
                      id="Ellipse_19"
                      data-name="Ellipse 19"
                      cx="23"
                      cy="23"
                      r="23"
                      transform="translate(1615 6793)"
                      fill="#f0f3f7"
                    />
                    <g id="Linkedin" transform="translate(1626 6803.5)">
                      <g
                        id="Linkedin-2"
                        data-name="Linkedin"
                        transform="translate(2 1)"
                      >
                        <path
                          id="Path"
                          d="M2.125,0A2.125,2.125,0,1,0,4.25,2.125,2.125,2.125,0,0,0,2.125,0Z"
                          transform="translate(0.625 0.875)"
                          fill="#0b0b0b"
                        />
                        <path
                          id="Path-2"
                          data-name="Path"
                          d="M.125,0A.125.125,0,0,0,0,.125v13a.125.125,0,0,0,.125.125h4a.125.125,0,0,0,.125-.125v-13A.125.125,0,0,0,4.125,0Z"
                          transform="translate(0.625 6.875)"
                          fill="#0b0b0b"
                        />
                        <path
                          id="Path-3"
                          data-name="Path"
                          d="M.125,0A.125.125,0,0,0,0,.125v13a.125.125,0,0,0,.125.125h4a.125.125,0,0,0,.125-.125v-7a1.875,1.875,0,0,1,3.75,0v7a.125.125,0,0,0,.125.125h4a.125.125,0,0,0,.125-.125V4.505A4.146,4.146,0,0,0,7.725.4,7.168,7.168,0,0,0,5.556.947l-1.306.56V.125A.125.125,0,0,0,4.125,0Z"
                          transform="translate(7.125 6.875)"
                          fill="#0b0b0b"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                </a>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={2}
                className="wow animate__fadeInDownBig mx-sm-2 mx-lg-0"
                data-wow-offset="100"
                data-wow-duration="1s"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                >
                  <g
                    id="Group_69"
                    data-name="Group 69"
                    transform="translate(-1682 -6793)"
                  >
                    <circle
                      id="Ellipse_18"
                      data-name="Ellipse 18"
                      cx="23"
                      cy="23"
                      r="23"
                      transform="translate(1682 6793)"
                      fill="#f0f3f7"
                    />
                    <g id="Whatsapp" transform="translate(1693 6804.5)">
                      <g
                        id="Whatsapp-2"
                        data-name="Whatsapp"
                        transform="translate(2 2)"
                      >
                        <g id="Group_144" data-name="Group 144">
                          <path
                            id="Combined_Shape"
                            data-name="Combined Shape"
                            d="M9.958,19.828l-.383-.008a10.043,10.043,0,0,1-4.38-1.2l-4.44,1.16a.51.51,0,0,1-.129.017.5.5,0,0,1-.4-.2.5.5,0,0,1-.084-.435l1.185-4.3-.181-.326A9.949,9.949,0,0,1,.006,10.274L0,9.912A9.968,9.968,0,0,1,17,2.905a9.824,9.824,0,0,1,2.914,7.011A9.946,9.946,0,0,1,9.958,19.828ZM6.307,5.165a.947.947,0,0,0-.688.319l-.046.049A2.819,2.819,0,0,0,4.72,7.627a4.916,4.916,0,0,0,1.047,2.646l.006.008c.009.013.027.039.053.077a10,10,0,0,0,4.35,3.8,7.179,7.179,0,0,0,2.562.685,3.015,3.015,0,0,0,.389-.028l.141-.016a2.613,2.613,0,0,0,1.741-1.221,2.151,2.151,0,0,0,.148-1.221c-.051-.086-.17-.143-.349-.23l-.146-.071c-.243-.121-1.523-.748-1.76-.837a.821.821,0,0,0-.277-.067.353.353,0,0,0-.3.2c-.081.119-.288.39-.816,1a.353.353,0,0,1-.277.147.644.644,0,0,1-.282-.083c-.042-.021-.1-.045-.168-.075a6.661,6.661,0,0,1-1.9-1.2A7.723,7.723,0,0,1,7.44,9.36c-.15-.26-.01-.4.113-.522.072-.072.158-.176.242-.277.049-.059.1-.118.145-.172a1.428,1.428,0,0,0,.228-.367l.03-.061a.477.477,0,0,0-.02-.448c-.029-.056-.146-.341-.281-.672-.174-.423-.388-.946-.515-1.228-.2-.432-.4-.438-.579-.444C6.654,5.165,6.478,5.165,6.307,5.165Z"
                            transform="translate(0.084 0)"
                            fill="#0b0b0b"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </Col>
              <Col
                lg={2}
                md={2}
                xs={2}
                className="wow animate__fadeInDownBig "
                data-wow-offset="100"
                data-wow-duration="1s"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                >
                  <g
                    id="Group_70"
                    data-name="Group 70"
                    transform="translate(-1750 -6793)"
                  >
                    <circle
                      id="Ellipse_17"
                      data-name="Ellipse 17"
                      cx="23"
                      cy="23"
                      r="23"
                      transform="translate(1750 6793)"
                      fill="#f0f3f7"
                    />
                    <g id="Google-alt" transform="translate(1761 6804.5)">
                      <g
                        id="Google-alt-2"
                        data-name="Google-alt"
                        transform="translate(3 3)"
                      >
                        <path
                          id="Path"
                          d="M17.855,7.361a.2.2,0,0,0-.195-.161H9.2a.2.2,0,0,0-.2.2v3.2a.2.2,0,0,0,.2.2h4.886a5.4,5.4,0,1,1-1.647-5.955.205.205,0,0,0,.276-.01l2.266-2.266a.2.2,0,0,0-.007-.286A8.991,8.991,0,1,0,18,9,9.492,9.492,0,0,0,17.855,7.361Z"
                          fill="#0b0b0b"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </Col>
            </Row>
          </Col>

          <Col
            lg={4}
            md={4}
            xs={12}
            className="d-md-none mt-4 text-center  mt-5 mb-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="165.45"
              height="27.174"
              viewBox="0 0 165.45 27.174"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_194"
                    data-name="Rectangle 194"
                    width="27.174"
                    height="27.174"
                    fill="#fff"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_104"
                data-name="Group 104"
                transform="translate(-235 -5915.542)"
              >
                <g
                  id="Group_38"
                  data-name="Group 38"
                  transform="translate(-26.27 5190.972)"
                >
                  <text
                    id="Powered_by_AutoMeta"
                    data-name="Powered by AutoMeta"
                    transform="translate(298.72 742.883)"
                    fill="#fff"
                    font-size="13"
                    font-family="SegoeUI, Segoe UI"
                  >
                    <tspan x="0" y="0">
                      Powered by AutoMeta
                    </tspan>
                  </text>
                  <g
                    id="Group_15"
                    data-name="Group 15"
                    transform="translate(261.27 724.57)"
                  >
                    <g
                      id="Group_14"
                      data-name="Group 14"
                      clip-path="url(#clip-path)"
                    >
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M96.992,150.735a6.57,6.57,0,0,1-5.611-1.317c-.061-.05-.122-.1-.185-.145a6.018,6.018,0,0,1-1.256-1.261,5.828,5.828,0,0,1-.95-1.962A6.1,6.1,0,0,1,89,143.276c.037-.208.425-2.345.912-2.335l.017,0c.423.1.64,1.406.726,1.84a7.7,7.7,0,0,0,1.869,3.466,5.664,5.664,0,0,0,1.259.955,3.914,3.914,0,0,0,1.369.541,4.724,4.724,0,0,0,1.2.009c.287-.021.561-.064,1.109-.151a5.014,5.014,0,0,0,.8-.164,3.973,3.973,0,0,0,.717-.314,4.111,4.111,0,0,0,.531-.354c.336-.259,1.853-1.36,2.156-1.053.288.292-.472,1.827-.584,2.04a6.626,6.626,0,0,1-4.09,2.977"
                        transform="translate(-78.389 -124.373)"
                        fill="#fff"
                      />
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M45.428,132.557a6.571,6.571,0,0,1-3.036-4.9c-.008-.078-.016-.156-.028-.234a6.015,6.015,0,0,1,0-1.78,5.83,5.83,0,0,1,.715-2.059,6.1,6.1,0,0,1,1.969-1.954c.173-.121,1.959-1.358,2.3-1.007a.074.074,0,0,1,.01.013c.229.369-.541,1.447-.788,1.815a7.7,7.7,0,0,0-1.129,3.772,5.664,5.664,0,0,0,.215,1.565,3.915,3.915,0,0,0,.585,1.351,4.738,4.738,0,0,0,.843.856c.218.188.442.351.891.677a5.006,5.006,0,0,0,.682.449,3.96,3.96,0,0,0,.729.285,4.1,4.1,0,0,0,.625.125c.421.054,2.272.349,2.269.78,0,.41-1.625.958-1.855,1.029a6.625,6.625,0,0,1-5-.787"
                        transform="translate(-37.327 -106.391)"
                        fill="#fff"
                      />
                      <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M5.692,96.992a6.57,6.57,0,0,1,1.317-5.611c.05-.061.1-.122.145-.185A6.017,6.017,0,0,1,8.415,89.94a5.828,5.828,0,0,1,1.962-.95A6.1,6.1,0,0,1,13.151,89c.208.037,2.345.425,2.335.912a.075.075,0,0,1,0,.017c-.1.423-1.406.64-1.84.726a7.7,7.7,0,0,0-3.466,1.869,5.664,5.664,0,0,0-.955,1.259,3.913,3.913,0,0,0-.541,1.369,4.725,4.725,0,0,0-.009,1.2c.021.287.064.561.151,1.109a5.016,5.016,0,0,0,.164.8,3.975,3.975,0,0,0,.314.717,4.108,4.108,0,0,0,.354.531c.259.336,1.36,1.853,1.053,2.156-.292.288-1.827-.472-2.04-.584a6.625,6.625,0,0,1-2.977-4.09"
                        transform="translate(-4.879 -78.389)"
                        fill="#fff"
                      />
                      <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M1.008,45.428a6.571,6.571,0,0,1,4.9-3.036c.078-.008.156-.016.234-.028a6.015,6.015,0,0,1,1.78,0,5.83,5.83,0,0,1,2.059.715,6.1,6.1,0,0,1,1.954,1.969c.121.173,1.358,1.959,1.007,2.3l-.013.01c-.369.229-1.447-.541-1.815-.788A7.7,7.7,0,0,0,7.34,45.441a5.664,5.664,0,0,0-1.565.215,3.915,3.915,0,0,0-1.351.585,4.737,4.737,0,0,0-.856.843c-.188.218-.351.442-.677.891a5.006,5.006,0,0,0-.45.682,3.96,3.96,0,0,0-.285.729,4.1,4.1,0,0,0-.125.625c-.054.421-.349,2.272-.78,2.269-.41,0-.958-1.625-1.029-1.855a6.626,6.626,0,0,1,.787-5"
                        transform="translate(0 -37.327)"
                        fill="#fff"
                      />
                      <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M37.345,5.692a6.57,6.57,0,0,1,5.611,1.317c.061.05.122.1.185.145A6.017,6.017,0,0,1,44.4,8.415a5.829,5.829,0,0,1,.95,1.962,6.1,6.1,0,0,1-.011,2.774c-.037.208-.425,2.345-.912,2.335l-.017,0c-.423-.1-.64-1.406-.726-1.84a7.7,7.7,0,0,0-1.869-3.466,5.664,5.664,0,0,0-1.259-.955,3.914,3.914,0,0,0-1.369-.541,4.725,4.725,0,0,0-1.2-.009c-.287.021-.561.064-1.109.151a5.016,5.016,0,0,0-.8.164,3.974,3.974,0,0,0-.717.314,4.107,4.107,0,0,0-.531.354c-.336.259-1.853,1.36-2.156,1.053-.288-.292.472-1.827.584-2.04a6.625,6.625,0,0,1,4.09-2.977"
                        transform="translate(-28.774 -4.879)"
                        fill="#fff"
                      />
                      <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M110.816,1.008a6.57,6.57,0,0,1,3.036,4.9c.008.078.016.156.028.234a6.015,6.015,0,0,1,0,1.78,5.83,5.83,0,0,1-.715,2.059,6.1,6.1,0,0,1-1.969,1.954c-.173.121-1.959,1.358-2.3,1.007a.073.073,0,0,1-.01-.013c-.229-.369.541-1.447.788-1.815A7.7,7.7,0,0,0,110.8,7.34a5.664,5.664,0,0,0-.215-1.565A3.915,3.915,0,0,0,110,4.423a4.737,4.737,0,0,0-.843-.856c-.218-.188-.442-.351-.891-.677a5.006,5.006,0,0,0-.682-.45,3.959,3.959,0,0,0-.729-.285,4.1,4.1,0,0,0-.625-.125c-.421-.054-2.272-.349-2.269-.78,0-.41,1.625-.958,1.855-1.029a6.626,6.626,0,0,1,5,.787"
                        transform="translate(-91.742 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M150.734,37.344a6.57,6.57,0,0,1-1.317,5.611c-.05.061-.1.122-.145.185a6.017,6.017,0,0,1-1.261,1.256,5.829,5.829,0,0,1-1.962.95,6.1,6.1,0,0,1-2.774-.011c-.208-.037-2.345-.425-2.335-.912a.075.075,0,0,1,0-.017c.1-.423,1.406-.64,1.84-.726a7.7,7.7,0,0,0,3.466-1.869,5.663,5.663,0,0,0,.955-1.259,3.913,3.913,0,0,0,.541-1.369,4.726,4.726,0,0,0,.009-1.2c-.021-.287-.064-.561-.151-1.109a5.016,5.016,0,0,0-.164-.8,3.975,3.975,0,0,0-.314-.717,4.1,4.1,0,0,0-.354-.531c-.259-.336-1.36-1.853-1.053-2.156.292-.288,1.827.472,2.04.584a6.625,6.625,0,0,1,2.977,4.09"
                        transform="translate(-124.373 -28.773)"
                        fill="#fff"
                      />
                      <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M132.557,110.816a6.571,6.571,0,0,1-4.9,3.036c-.078.008-.156.016-.234.028a6.011,6.011,0,0,1-1.78,0,5.83,5.83,0,0,1-2.059-.715,6.1,6.1,0,0,1-1.954-1.969c-.121-.173-1.358-1.959-1.007-2.3l.013-.01c.369-.229,1.447.542,1.815.788a7.7,7.7,0,0,0,3.772,1.129,5.669,5.669,0,0,0,1.565-.215,3.917,3.917,0,0,0,1.351-.586,4.732,4.732,0,0,0,.856-.843c.188-.218.351-.442.677-.891a5.013,5.013,0,0,0,.449-.682,3.954,3.954,0,0,0,.285-.729,4.1,4.1,0,0,0,.125-.625c.054-.421.349-2.272.78-2.269.41,0,.958,1.625,1.029,1.855a6.625,6.625,0,0,1-.787,5"
                        transform="translate(-106.391 -91.742)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </Col>
        </Row>
      </div>

      {/*-------------- Modal   */}
      <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
          
          <MDBModalBody className="text-center">
          <div style={{color:"#14A44D"}}><i color="successfull" className="fas fa-check-circle fa-5x"></i></div>
          <p>Your information has been received successfully. We will be in touch with you shortly.</p>
      </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/*-------------- Modal   */}
      <MDBModal open={errorModal} onClose={() => setErrorModal(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
          
          <MDBModalBody className="text-center">
          <div style={{color:"#DC4C64"}}><i color="successfull" className="fas fa-times-circle fa-5x"></i></div>
          <p>Sorry, we are experiencing a server problem. Please try again later.</p>
      </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    </div>
  );
};

export default GetStart;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

const firebaseConfig = {
  apiKey: "AIzaSyBBg5kTxar02ERrGosOYheFA4ndJd82DzQ",
  authDomain: "autometa-61b53.firebaseapp.com",
  projectId: "autometa-61b53",
  storageBucket: "autometa-61b53.appspot.com",
  messagingSenderId: "607552661504",
  appId: "1:607552661504:web:a8ca6d66dde149296ad0e5",
  measurementId: "G-19XZ2N005D"
};

// Initialize Firebase
 initializeApp(firebaseConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

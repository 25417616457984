import { Container, Row } from "react-bootstrap";
import WOW from "wowjs";
import React, { useEffect } from "react";
import "animate.css";

const Start = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div className="Start" id="start">
      <Container>
        <Row>
          <div>
            <div
              className="Startdev d-flex justify-content-center align-items-center wow slideInLeft"
              data-wow-duration="2s"
              data-wow-delay="2s"
            >
              <p>
                {" "}
                The very best in everything we offer.
                <img
                  style={{ height: "22px", width: "22px", marginLeft: "5px" }}
                  src={require("../assets/Start/Component 42 – 1.png")}
                  alt="logo"
                />
              </p>
            </div>
          </div>
        </Row>
        <Row>
          <div
            className="bigText wow animate__pulse"
            data-wow-offset="400"
            data-wow-duration="2s"
          >
            <p>
              Unlock the potential of technology with Autometa, where creativity
              meets unrivaled quality.
            </p>
          </div>
        </Row>
        <Row>
          <div className="smallText d-flex justify-content-center align-items-center">
            <p>
              Experience the incredible possibilities of technology with
              Autometa, where creativity and exceptional quality come together.
              At Autometa, we find inspiration in the first robot to invent
              Automata. This name reflects our commitment to innovation and our
              drive to be at the forefront of technology. Just like that
              pioneering robot, we aim to be the very best in everything we
              offer.
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Start;

import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { Navbar, Container, Nav, Offcanvas } from "react-bootstrap";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import useUser from "../hooks/useUser";
const NavBar = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  return (
    <div>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary px-4">
          <Navbar.Brand href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="135"
              height="34"
              viewBox="0 0 135 34"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="135"
                    height="34"
                    transform="translate(0 0)"
                    fill="none"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_5"
                data-name="Group 5"
                transform="translate(-0.5 0.41)"
              >
                <g
                  id="Group_2"
                  data-name="Group 2"
                  transform="translate(0.5 -0.41)"
                >
                  <g
                    id="Group_1"
                    data-name="Group 1"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M84.688,130.6a7.852,7.852,0,0,1-6.705-1.574c-.073-.059-.146-.118-.221-.174a7.2,7.2,0,0,1-1.5-1.506A6.97,6.97,0,0,1,75.125,125a7.293,7.293,0,0,1,.013-3.315c.044-.248.507-2.8,1.09-2.791l.02,0c.505.119.765,1.68.868,2.2a9.206,9.206,0,0,0,2.234,4.142,6.768,6.768,0,0,0,1.5,1.141,4.679,4.679,0,0,0,1.636.647,5.654,5.654,0,0,0,1.436.011c.343-.025.671-.077,1.326-.181a6,6,0,0,0,.956-.2,4.744,4.744,0,0,0,.857-.375,4.9,4.9,0,0,0,.634-.423c.4-.31,2.215-1.625,2.576-1.258.344.349-.564,2.183-.7,2.438a7.918,7.918,0,0,1-4.888,3.558"
                      transform="translate(-63.261 -98.433)"
                      fill="#111b33"
                    />
                    <path
                      id="Path_2"
                      data-name="Path 2"
                      d="M39.421,116.037a7.853,7.853,0,0,1-3.628-5.854c-.01-.094-.02-.186-.033-.279a7.187,7.187,0,0,1,0-2.127,6.971,6.971,0,0,1,.854-2.461,7.292,7.292,0,0,1,2.353-2.335c.207-.145,2.341-1.623,2.744-1.2a.1.1,0,0,1,.012.016c.273.441-.647,1.729-.941,2.168a9.206,9.206,0,0,0-1.349,4.508,6.77,6.77,0,0,0,.256,1.871,4.677,4.677,0,0,0,.7,1.615,5.656,5.656,0,0,0,1.008,1.023c.26.225.529.42,1.065.81a6,6,0,0,0,.815.537,4.733,4.733,0,0,0,.871.341,4.923,4.923,0,0,0,.747.15c.5.064,2.716.417,2.712.932,0,.49-1.942,1.145-2.217,1.23a7.918,7.918,0,0,1-5.973-.941"
                      transform="translate(-30.545 -84.107)"
                      fill="#111b33"
                    />
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M4.859,84.688a7.852,7.852,0,0,1,1.574-6.705c.059-.073.118-.146.174-.221a7.194,7.194,0,0,1,1.506-1.5,6.97,6.97,0,0,1,2.344-1.136,7.294,7.294,0,0,1,3.315.013c.248.044,2.8.507,2.791,1.09a.088.088,0,0,1,0,.02c-.119.505-1.68.765-2.2.868a9.206,9.206,0,0,0-4.142,2.234,6.767,6.767,0,0,0-1.141,1.5,4.678,4.678,0,0,0-.647,1.636,5.645,5.645,0,0,0-.011,1.436c.025.343.077.671.181,1.326a5.968,5.968,0,0,0,.2.956,4.738,4.738,0,0,0,.375.857A4.9,4.9,0,0,0,9.6,87.7c.31.4,1.626,2.215,1.258,2.576-.349.344-2.183-.564-2.438-.7a7.918,7.918,0,0,1-3.558-4.888"
                      transform="translate(-4.692 -61.798)"
                      fill="#111b33"
                    />
                    <path
                      id="Path_4"
                      data-name="Path 4"
                      d="M1.2,39.421a7.853,7.853,0,0,1,5.854-3.628c.094-.01.186-.02.279-.033a7.187,7.187,0,0,1,2.127,0,6.971,6.971,0,0,1,2.461.854,7.292,7.292,0,0,1,2.335,2.353c.145.207,1.623,2.341,1.2,2.744l-.016.012c-.441.273-1.729-.647-2.168-.941a9.206,9.206,0,0,0-4.508-1.349,6.77,6.77,0,0,0-1.871.256,4.677,4.677,0,0,0-1.615.7A5.656,5.656,0,0,0,4.263,41.4c-.225.26-.42.529-.81,1.065a6,6,0,0,0-.537.815,4.732,4.732,0,0,0-.341.871,4.922,4.922,0,0,0-.15.747c-.064.5-.417,2.716-.932,2.712-.49,0-1.145-1.942-1.23-2.217A7.918,7.918,0,0,1,1.2,39.421"
                      transform="translate(-0.804 -29.081)"
                      fill="#111b33"
                    />
                    <path
                      id="Path_5"
                      data-name="Path 5"
                      d="M33.17,4.86a7.852,7.852,0,0,1,6.705,1.574c.073.059.146.118.221.174a7.194,7.194,0,0,1,1.5,1.506,6.97,6.97,0,0,1,1.136,2.344,7.293,7.293,0,0,1-.013,3.315c-.044.248-.507,2.8-1.09,2.791l-.02,0c-.505-.119-.765-1.68-.868-2.2a9.206,9.206,0,0,0-2.234-4.142A6.767,6.767,0,0,0,37,9.08a4.678,4.678,0,0,0-1.636-.647,5.653,5.653,0,0,0-1.436-.011c-.343.025-.671.077-1.326.181a6,6,0,0,0-.956.2,4.742,4.742,0,0,0-.857.375,4.9,4.9,0,0,0-.634.422c-.4.31-2.215,1.625-2.576,1.258-.344-.349.564-2.183.7-2.438A7.918,7.918,0,0,1,33.17,4.86"
                      transform="translate(-23.73 -3.229)"
                      fill="#111b33"
                    />
                    <path
                      id="Path_6"
                      data-name="Path 6"
                      d="M95.889,1.2a7.853,7.853,0,0,1,3.628,5.854c.01.094.02.186.033.279a7.188,7.188,0,0,1,0,2.127,6.971,6.971,0,0,1-.854,2.461,7.292,7.292,0,0,1-2.353,2.335c-.207.145-2.341,1.623-2.744,1.2a.1.1,0,0,1-.012-.016c-.273-.441.647-1.729.941-2.168a9.205,9.205,0,0,0,1.349-4.508A6.769,6.769,0,0,0,95.617,6.9a4.676,4.676,0,0,0-.7-1.615,5.656,5.656,0,0,0-1.008-1.023c-.26-.225-.529-.42-1.065-.81a6.005,6.005,0,0,0-.815-.537,4.732,4.732,0,0,0-.871-.341,4.921,4.921,0,0,0-.747-.15c-.5-.064-2.716-.417-2.712-.932C87.7,1,89.641.349,89.916.264a7.918,7.918,0,0,1,5.973.941"
                      transform="translate(-73.899 0.66)"
                      fill="#111b33"
                    />
                    <path
                      id="Path_7"
                      data-name="Path 7"
                      d="M130.6,33.169a7.852,7.852,0,0,1-1.574,6.705c-.059.073-.118.146-.174.221a7.194,7.194,0,0,1-1.506,1.5A6.97,6.97,0,0,1,125,42.732a7.293,7.293,0,0,1-3.315-.013c-.248-.044-2.8-.507-2.791-1.09a.089.089,0,0,1,0-.02c.119-.505,1.68-.765,2.2-.868a9.206,9.206,0,0,0,4.142-2.234,6.767,6.767,0,0,0,1.141-1.5,4.679,4.679,0,0,0,.647-1.636,5.654,5.654,0,0,0,.011-1.436c-.025-.343-.077-.671-.181-1.326a6,6,0,0,0-.2-.956,4.743,4.743,0,0,0-.375-.857,4.9,4.9,0,0,0-.423-.634c-.31-.4-1.625-2.215-1.258-2.576.349-.344,2.183.564,2.438.7a7.918,7.918,0,0,1,3.558,4.888"
                      transform="translate(-99.897 -22.265)"
                      fill="#111b33"
                    />
                    <path
                      id="Path_8"
                      data-name="Path 8"
                      d="M116.038,95.889a7.853,7.853,0,0,1-5.854,3.628c-.094.01-.186.02-.279.033a7.188,7.188,0,0,1-2.127,0,6.972,6.972,0,0,1-2.461-.854,7.292,7.292,0,0,1-2.335-2.353c-.145-.207-1.623-2.341-1.2-2.744l.016-.012c.441-.273,1.729.647,2.168.941a9.205,9.205,0,0,0,4.508,1.349,6.77,6.77,0,0,0,1.871-.256,4.676,4.676,0,0,0,1.615-.7,5.656,5.656,0,0,0,1.023-1.008c.225-.26.42-.529.81-1.065a6,6,0,0,0,.537-.815,4.731,4.731,0,0,0,.341-.871,4.923,4.923,0,0,0,.15-.747c.064-.5.417-2.716.932-2.712.49,0,1.145,1.942,1.23,2.217a7.918,7.918,0,0,1-.941,5.973"
                      transform="translate(-85.572 -72.435)"
                      fill="#111b33"
                    />
                  </g>
                </g>
                <text
                  id="AutoMeta"
                  transform="translate(34.065 22.572)"
                  fill="#111b33"
                  font-size="17"
                  font-family="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0" letter-spacing="0.015em">
                    A
                  </tspan>
                  <tspan y="0" letter-spacing="0.014em">
                    ut
                  </tspan>
                  <tspan y="0" letter-spacing="0.015em">
                    oMe
                  </tspan>
                  <tspan y="0" letter-spacing="0.014em">
                    t
                  </tspan>
                  <tspan y="0">a</tspan>
                </text>
              </g>
            </svg>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                AutoMeta
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center" activeKey="/home">
                  <Nav.Link className="mx-4" href="#start">Main</Nav.Link>
                  <Nav.Link className="mx-4" href="#services">Services</Nav.Link>
                  <Nav.Link className="mx-4" href="#product">Products</Nav.Link>
                  <Nav.Link className="mx-4" href="#blogs">Blogs</Nav.Link>
                  <Nav.Link className="mx-4" href="#story">Company</Nav.Link>
                
              </Nav>
              <nav></nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      ))}
    </div>
  );
};

export default NavBar;

/*

<nav>
<ul>
  <li>
     <Link to='/'>Home</Link>
   </li>
  <li>
     <Link to='/about'>About</Link>
   </li>
  <li>
     <Link to='/articles'>Articles</Link>
   </li>
 
</ul>
<div className="nav-right">
 {user 
 ? <button
    onClick={() => {signOut(getAuth())}}
 >Log Out</button>
 :<button onClick={()=> {navigate('/login')}}>Log In</button>
}
</div>
</nav>

*/

import { Container, Row, Col, Carousel, Button, Card } from "react-bootstrap";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCardFooter,
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import ReactCardSlider from 'react-card-slider-component';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WOW from "wowjs";
import { useEffect } from "react";
import "animate.css";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";


const Services = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  var settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          //  dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="Services" id="services">
      <Container>
        <Row>
          <div
            className=" text-center wow animate__fadeIn"
            data-wow-offset="100"
            data-wow-duration="1s"
          >
            <h6>Services & Solutions</h6>
            <p> Unleash your business potential with Autometa's <br></br>
            cutting-edge Products, propelling your digital success.</p>
          </div>
        </Row>
        
        <div className="slider-container" style={{ height: "525px", width:"90%" ,marginRight:"-50px"}}>
        <Slider {...settings}>
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            style={{ marginLeft: "10px" }}
            src={require("../assets/solutions/solutions-01.png")}
            alt="logo"
          />
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            src={require("../assets/solutions/solutions-02.png")}
            alt="logo"
          />
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            src={require("../assets/solutions/solutions-03.png")}
            alt="logo"
          />
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            src={require("../assets/solutions/solutions-04.png")}
            alt="logo"
          />
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            src={require("../assets/solutions/solutions-05.png")}
            alt="logo"
          />
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            src={require("../assets/solutions/solutions-06.png")}
            alt="logo"
          />
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            src={require("../assets/solutions/solutions-07.png")}
            alt="logo"
          />
          <img
            className="wow animate__fadeInLeft"
            data-wow-offset="100"
            data-wow-duration={`${1}s`}
            src={require("../assets/solutions/solutions-08.png")}
            alt="logo"
          />
        </Slider>
      </div>

        <Row>
         
        </Row>
      </Container>
    </div>
  );
};

export default Services;




/*

 <div className="slider-container" style={{ height: "525px" }}>
            <Slider {...settings}>
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                style={{ marginLeft: "10px" }}
                src={require("../assets/solutions/solutions-01.png")}
                alt="logo"
              />
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                src={require("../assets/solutions/solutions-02.png")}
                alt="logo"
              />
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                src={require("../assets/solutions/solutions-03.png")}
                alt="logo"
              />
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                src={require("../assets/solutions/solutions-04.png")}
                alt="logo"
              />
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                src={require("../assets/solutions/solutions-05.png")}
                alt="logo"
              />
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                src={require("../assets/solutions/solutions-06.png")}
                alt="logo"
              />
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                src={require("../assets/solutions/solutions-07.png")}
                alt="logo"
              />
              <img
                className="wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration={`${1}s`}
                src={require("../assets/solutions/solutions-08.png")}
                alt="logo"
              />
            </Slider>
          </div>

          <ReactCardSlider slides={slides}/>
          
*/
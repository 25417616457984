import { Col, Row } from "react-bootstrap";
import { MDBBtn } from "mdb-react-ui-kit";
import WOW from 'wowjs';
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

const Buttons = () => {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
      }, []);
      
      const GetStartClickHandler = () => {
        ReactGA.event({
            category: 'Button',
            action: 'Click Get Start Button',
          });
        
    }

    const companyStoryClickHandler = () => {
      ReactGA.event({
          category: 'Button',
          action: 'Click Compony Story Button',
        });
      
  }


  return (
    <div className="container">
      <div className="buttons">
        <Row>
          <Col className="justify-content-end text-end colb1">
          <Link to="contactus">
          <MDBBtn onClick={GetStartClickHandler} className="b1 wow animate__fadeInLeft" data-wow-offset="100" data-wow-duration="1s" >Get Started</MDBBtn>
          </Link>
            
          </Col>
          <Col>
          <a  href="#story">
          <MDBBtn onClick={companyStoryClickHandler} className="b2 wow animate__fadeInRight" data-wow-offset="100" data-wow-duration="1s">Company story</MDBBtn>
          </a>
           
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Buttons;

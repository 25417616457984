import { useState, useEffect } from "react";
import NavBar from "../component/NavBar";
import ReactGA from "react-ga4";
import Start from "../component/Start";
import Buttons from "../component/Buttons";
import Features from "../component/Features";
import TrustedBy from "../component/TrustedBy";
import OurProduct from "../component/OurProduct";
import Services from "../component/Services";
import Blogs from "../component/Blogs";
import Footer from "../component/Footer";

const HomePage = () => {
    useEffect(() => {
        ReactGA.initialize("G-PB8W8W89QW");
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname + window.location.search,
          title: "Home Page"
        });
    
      
    
      }, []);

    return ( 
        <div>
        <NavBar />
        <Start />
        <Buttons />
        <Features />
        <TrustedBy />
        <Services />
        <OurProduct />
        <Blogs />
        <Footer />
        </div>
     );
}
 
export default HomePage;
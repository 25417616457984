import { Container, Row ,Col} from "react-bootstrap";
import AwesomeSlider from 'react-awesome-slider';
import WOW from 'wowjs';
import React, { useEffect } from 'react';
import 'animate.css';

const TrustedBy = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  
  return (
    <div className="TrustedBy text-center" >
      <Container>
        <Row>
        <div className=" wow animate__fadeIn"  data-wow-offset="100" data-wow-duration="3s">
          <h6>Trusted By</h6>
          
          <span>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="far fa-star" style={{color:"#5B7486"}}></i>
          </span>
          </div>
          <Row style= {{marginTop:"60px"}}>
        
          <div class="logos">
          <div class="logos-slide">
          <img src={ require('../assets/Group 17.png') } alt="logo"/>
          <img src={ require('../assets/Component 31 – 1.png') } alt="logo"/>
            <img src={ require('../assets/Group 19.png') } alt="logo"/>
            <img src={ require('../assets/Group 21.png') } alt="logo"/>
            <img src={ require('../assets/Group 54.png') } alt="logo"/>
            <img src={ require('../assets/Group 25.png') }   alt="logo"/>
            <img src={ require('../assets/Group 22.png') } alt="logo"/>
            <img src={ require('../assets/Component 30 – 1.png') } alt="logo"/>
            <img src={ require('../assets/Component 31 – 1.png') } alt="logo"/>
            <img src={ require('../assets/Component 33 – 1.png') } alt="logo"/>
            <img src={ require('../assets/Component 34 – 1.png') } alt="logo"/>
            <img src={ require('../assets/Component 35 – 1.png') } alt="logo"/>
            <img src={ require('../assets/Component 36 – 1.png') } alt="logo"/>
            <img src={ require('../assets/Component 39 – 2.png') } alt="logo"/>
          </div>
        </div>
            
          
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default TrustedBy;




/*

 <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="1s">
            <img src={ require('../assets/Group 17.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="1.4s">
            <img src={ require('../assets/Group 19.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="1.7s">
            <img src={ require('../assets/Group 21.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__fadeIn"  data-wow-offset="100" data-wow-duration="2s"> 
            <img src={ require('../assets/Group 22.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__fadeIn"  data-wow-offset="100" data-wow-duration="2.5s">
            <img src={ require('../assets/Group 25.png') } width={90}  alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="2.5s">
            <img src={ require('../assets/Group 54.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="3s">
            <img src={ require('../assets/Component 30 – 1.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="3s">
            <img src={ require('../assets/Component 31 – 1.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="3s">
            <img src={ require('../assets/Component 33 – 1.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="4s">
            <img src={ require('../assets/Component 34 – 1.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="4s">
            <img src={ require('../assets/Component 35 – 1.png') }width={90} alt="logo"/>
            </Col>
            <Col lg={2} md={2} sm={3} xs={4} className="mt-4 wow animate__slideInUp"  data-wow-offset="100" data-wow-duration="4s">
            <img src={ require('../assets/Component 36 – 1.png') }width={90} alt="logo"/>
            </Col>

*/
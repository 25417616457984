import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { ReactComponent as Image2 } from "../assets/Group.svg";
import { ReactComponent as Image3 } from "../assets/img3.svg";
import { ReactComponent as Ai } from "../assets/Group 48.svg";
import WOW from 'wowjs';
import  { useEffect } from 'react';
import 'animate.css';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import { faAirFreshener, faClock, faImage } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, ProgressBar } from "react-bootstrap";
const Features = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  
  return (
    <div id="features">
      <div className="container">
        <div className="featuers">
          <Row>
            <Col lg={3} md={6} xs={12} className="mt-2">
              <MDBCard className="wow animate__slideInDown" data-wow-offset="100" data-wow-duration="2s">
                <MDBCardBody>
                  <MDBCardTitle>
                    Get in touch with our Consltant to find solutions or train
                  </MDBCardTitle>
                  <MDBCardText>
                    
                    <h3>
                      Certified Engineers and Consltant
                      <svg
                        id="medal-f"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.145"
                        height="21.285"
                        viewBox="0 0 15.145 21.285"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="0.295"
                            y1="1"
                            x2="0.788"
                            y2="0.239"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stop-color="#2648da" />
                            <stop offset="1" stop-color="#26d9bc" />
                          </linearGradient>
                        </defs>
                        <path
                          id="medal-f-2"
                          data-name="medal-f"
                          d="M4,17.5a1.223,1.223,0,0,0,1.067.133L7.6,16.771a1.23,1.23,0,0,1,.794,0l2.536.864A1.226,1.226,0,0,0,12,17.5V21.4L8,20.038,4,21.4V17.5Zm3.677-2.37-2.062.7a1,1,0,0,1-1.222-.51l-1-2.065a1,1,0,0,0-.43-.447l-2-1.06a1,1,0,0,1-.485-1.181L1.183,8.3a1,1,0,0,0,0-.6L.473,5.43A1,1,0,0,1,.959,4.248l2-1.06a1,1,0,0,0,.43-.446L4.393.674A1,1,0,0,1,5.615.165l2.062.7a1,1,0,0,0,.646,0l2.062-.7a1,1,0,0,1,1.222.51l1.005,2.066a1,1,0,0,0,.43.447l2,1.06a1,1,0,0,1,.485,1.181L14.817,7.7a1,1,0,0,0,0,.6l.71,2.273a1,1,0,0,1-.486,1.182l-2,1.06a1,1,0,0,0-.43.446l-1.005,2.067a1,1,0,0,1-1.222.509l-2.062-.7A1,1,0,0,0,7.677,15.132ZM8,5A1,1,0,0,0,7,6v4a1,1,0,0,0,2,0V6A1,1,0,0,0,8,5Z"
                          transform="translate(-0.427 -0.111)"
                          fill="url(#linear-gradient)"
                        />
                      </svg>
                    </h3>
                    <div style={{ marginTop: "10px" }}>
                      <Row style={{ marginTop: "8px" }}>
                        <Col lg={2} md={2} xs={2} sm={2}>
                          <div className="me-3">
                            <img
                              src={require("../assets/Ellipse-image.png")}
                              alt="logo"
                              width={40}
                              height={40}
                              style={{ borderRadius: "50px !important" }}
                            />
                          </div>
                        </Col>
                        <Col lg={10} md={10} xs={10} sm={10} className="mt-2">
                          <div>
                            <h6
                              className="card-title mb-1"
                              style={{ fontSize: "0.8rem" }}
                            >
                              <Row>
                                <Col>Project Progress</Col>
                                <Col className="text-center">
                                  <span>32%</span>
                                </Col>
                              </Row>
                            </h6>

                            <ProgressBar
                              now={32}
                              striped
                              animated
                              className="mb-0"
                              style={{ width: "80%", borderRadius: "20px" }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "8px" }}>
                        <Col lg={2} md={2} xs={2} sm={2}>
                          <div className="me-3">
                            <Image2 />
                          </div>
                        </Col>
                        <Col lg={10} md={10} xs={10} sm={10} className="mt-2">
                          <div>
                            <h6
                              className="card-title mb-1"
                              style={{ fontSize: "0.8rem" }}
                            >
                              <Row>
                                <Col>Project Progress</Col>
                                <Col className="text-center">
                                  <span>50%</span>
                                </Col>
                              </Row>
                            </h6>

                            <ProgressBar
                              now={50}
                              striped
                              animated
                              className="mb-0"
                              style={{ width: "80%", borderRadius: "20px" }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "8px" }}>
                        <Col lg={2} md={2} xs={2} sm={2}>
                          <div className="me-3">
                            <Image3 />
                          </div>
                        </Col>
                        <Col lg={10} md={10} xs={10} sm={10} className="mt-2">
                          <div>
                            <h6
                              className="card-title mb-1"
                              style={{ fontSize: "0.8rem" }}
                            >
                              <Row>
                                <Col>Project Progress</Col>
                                <Col className="text-center">
                                  <span>98%</span>
                                </Col>
                              </Row>
                            </h6>

                            <ProgressBar
                              now={98}
                              striped
                              animated
                              className="mb-0"
                              style={{ width: "80%", borderRadius: "20px" }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "8px" }}>
                        <Col lg={2} md={2} xs={2} sm={2}>
                          <div className="me-3">
                            <img
                              src={require("../assets/img4.png")}
                              alt="logo"
                              width={40}
                              height={40}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                        </Col>
                        <Col lg={10} md={10} xs={10} sm={10} className="mt-2">
                          <div>
                            <h6
                              className="card-title mb-1"
                              style={{ fontSize: "0.8rem" }}
                            >
                              <Row>
                                <Col>Project Progress</Col>
                                <Col className="text-center">
                                  <span>50%</span>
                                </Col>
                              </Row>
                            </h6>

                            <ProgressBar
                              now={50}
                              striped
                              animated
                              className="mb-0"
                              style={{ width: "80%", borderRadius: "20px" }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "8px" }}>
                        <Col lg={2} md={2} xs={2} sm={2}>
                          <div className="me-3">
                            <img
                              src={require("../assets/img5.png")}
                              alt="logo"
                              width={40}
                              height={40}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                        </Col>
                        <Col lg={10} md={10} xs={10} sm={10} className="mt-2">
                          <div>
                            <h6
                              className="card-title mb-1"
                              style={{ fontSize: "0.8rem" }}
                            >
                              <Row>
                                <Col>Project Progress</Col>
                                <Col className="text-center">
                                  <span>92%</span>
                                </Col>
                              </Row>
                            </h6>

                            <ProgressBar
                              now={92}
                              striped
                              animated
                              className="mb-0"
                              style={{ width: "80%", borderRadius: "20px" }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </Col>

            <Col lg={3} md={6} xs={12} className="mt-2">
              <MDBCard className="wow animate__fadeInDown" data-wow-offset="100" data-wow-duration="2s">
                <MDBCardBody>
                  <MDBCardTitle>
                  Analysis ,Stratigies to move up
                  forword
                  </MDBCardTitle>
                  <MDBCardText>
                    
                    <div style={{ marginTop: "10px" }}>
                      <Row style={{ marginTop: "8px" }}>
                        <div className="liner">
                        <img
                          src={require("../assets/Featuers/Group 222.png")}
                          alt="logo"
                          height={232}
                          style={{ borderRadius: "" }}
                        />
                        </div>
                      </Row>
                    </div>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </Col>

            <Col lg={3} md={6} xs={12} className="mt-2">
              <MDBCard className="wow animate__fadeInDown" data-wow-offset="100" data-wow-duration="2s">
                <MDBCardBody>
                  <MDBCardTitle>
                  Transformation is the key to
                  future business
                  </MDBCardTitle>
                  <MDBCardText>
                    
                  
                    <div style={{ marginTop: "10px" }} className="imgcard2">
                        <img
                       
                          src={require("../assets/Featuers/Group 223@2x.jpg")}
                          alt="logo"
                          height={320}
                          style={{marginTop:"-60px"}}
                        />
                    </div>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </Col>

            <Col lg={3} md={6} xs={12} className="mt-2">
              <MDBCard className="wow animate__fadeInDown" data-wow-offset="100" data-wow-duration="2s">
                <MDBCardBody>
                  <MDBCardTitle>
                  Branding, We have Completed
                  Creative Stedio
                  </MDBCardTitle>
                  <MDBCardText>
                  
                    <div style={{ marginTop: "10px" }}>
                      <Row style={{ marginTop: "8px" }}>
                      <h1 className="">
                     <span className="we d-block" >We <Ai className="ms-5"/>  </span> Help To Build Your Dream Project 
                      </h1>
                      
                      </Row>
                    </div>
                  </MDBCardText>
                 
                </MDBCardBody>
              </MDBCard>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Features;

import { Container, Row, Col } from "react-bootstrap";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import WOW from "wowjs";
import "animate.css";

const OurProduct = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  var scrollAni = 200;
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > scrollAni) {
        setShowAnimation(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div className="OurProduct text-center wrap2" id="product">
      <Container>
        <Row>
          <div
            className="wow animate__fadeIn"
            data-wow-offset="100"
            data-wow-duration="1s"
          >
            <h6>Our Product</h6>
            <p>
              +30 Your business productivity <br></br>
              with our technology solutions.
            </p>
          </div>
          <div>
            <Row>
              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="1s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="51"
                          height="51"
                          viewBox="0 0 51 51"
                        >
                          <path
                            id="coin-f"
                            d="M22.95,34.008h-.13a2.42,2.42,0,0,1-2.42-2.42,2.55,2.55,0,0,0-5.1,0,7.52,7.52,0,0,0,7.52,7.52h.13v2.55a2.55,2.55,0,0,0,5.1,0v-2.55a7.65,7.65,0,0,0,0-15.3v-5.1h.056A2.494,2.494,0,0,1,30.6,21.2a2.55,2.55,0,1,0,5.1,0,7.594,7.594,0,0,0-7.594-7.594H28.05v-2.55a2.55,2.55,0,0,0-5.1,0v2.55a7.65,7.65,0,1,0,0,15.3Zm5.1,0v-5.1a2.55,2.55,0,0,1,0,5.1Zm-5.1-15.3v5.1a2.55,2.55,0,1,1,0-5.1Zm2.55,33.15A25.5,25.5,0,1,1,51,26.358,25.5,25.5,0,0,1,25.5,51.858Z"
                            transform="translate(0 -0.858)"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>
                        Forward Financial and Administrative Affairs System
                        </MDBCardTitle>
                        <MDBCardText>
                          Simplify and streamline your financial and
                          administrative processes with our advanced system. It
                          offers comprehensive functionality to enhance
                          efficiency and productivity.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="1s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35.411"
                          height="41.4"
                          viewBox="0 0 35.411 41.4"
                        >
                          <path
                            id="accessibility"
                            d="M17.409,29.549l-.956,9.107a2.95,2.95,0,1,1-5.868-.62l2.133-20.306H2.953a2.955,2.955,0,1,1,0-5.909h29.5a2.955,2.955,0,0,1,0,5.909H22.69l2.133,20.312a2.953,2.953,0,1,1-5.871.617L18,29.549Zm.3-17.731a5.909,5.909,0,1,1,5.9-5.909,5.9,5.9,0,0,1-5.9,5.909Z"
                            transform="translate(0.002)"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>
                          Administrative communication system
                        </MDBCardTitle>
                        <MDBCardText>
                          Improve internal communication, collaboration, and
                          information sharing with our centralized platform.
                          This boosts productivity across your teams.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="1s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="37.5"
                          height="30"
                          viewBox="0 0 37.5 30"
                        >
                          <path
                            id="folder-open-f"
                            d="M0,9.375V5.625A5.625,5.625,0,0,1,5.625,0H15a5.632,5.632,0,0,1,5.306,3.75H31.875A5.625,5.625,0,0,1,37.5,9.375Zm0,3.75H37.5v11.25A5.625,5.625,0,0,1,31.875,30H5.625A5.625,5.625,0,0,1,0,24.375Z"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col
                      lg={10}
                      md={10}
                      xs={10}
                      className="proMarginTop wow animate__slideInUp"
                      data-wow-offset="100"
                      data-wow-duration="2s"
                    >
                      <MDBCardBody>
                        <MDBCardTitle>
                          Archiving and documentation system
                        </MDBCardTitle>
                        <MDBCardText>
                          Securely store and manage your documents with our
                          archiving and documentation system. It provides
                          features for organizing, categorizing, and searching
                          documents, ensuring easy retrieval when needed.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="2s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="39.508"
                          height="36"
                          viewBox="0 0 39.508 36"
                        >
                          <path
                            id="messages-f"
                            d="M7.222,35.515a1.975,1.975,0,0,1-3.271-1.454V29.631A3.951,3.951,0,0,1,0,25.68V11.852A3.951,3.951,0,0,1,3.951,7.9H25.68a3.951,3.951,0,0,1,3.951,3.951V25.68a3.951,3.951,0,0,1-3.951,3.951H15.809L7.222,35.515ZM31.606,19.787q-.009.364,0,.729v-.731q.015-.317.008-7.939a5.926,5.926,0,0,0-5.926-5.92H9.877V3.951A3.951,3.951,0,0,1,13.828,0H35.557a3.951,3.951,0,0,1,3.951,3.951V17.778a3.951,3.951,0,0,1-3.951,3.951V26.16a1.975,1.975,0,0,1-3.271,1.454l-.68-.466Z"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>
                        Visual Advertising and SMS System
                        </MDBCardTitle>
                        <MDBCardText>
                          Effectively reach your target audience with visually
                          appealing advertisements and targeted SMS campaigns.
                          This maximizes your marketing efforts.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="2s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34.411"
                          height="34.411"
                          viewBox="0 0 34.411 34.411"
                        >
                          <path
                            id="task-list-f"
                            d="M10.323,0H24.088A10.323,10.323,0,0,1,34.411,10.323V24.088A10.323,10.323,0,0,1,24.088,34.411H10.323A10.323,10.323,0,0,1,0,24.088V10.323A10.323,10.323,0,0,1,10.323,0ZM20.647,15.485a1.721,1.721,0,1,0,0,3.441h5.162a1.721,1.721,0,1,0,0-3.441Zm-3.441,6.882a1.721,1.721,0,1,0,0,3.441h8.6a1.721,1.721,0,1,0,0-3.441Zm0-13.765a1.721,1.721,0,0,0,0,3.441h8.6a1.721,1.721,0,1,0,0-3.441Zm-7.178,9.021L8.811,16.407A1.72,1.72,0,1,0,6.378,18.84l2.433,2.433a1.721,1.721,0,0,0,2.435,0l4.866-4.866a1.722,1.722,0,1,0-2.433-2.436l-3.651,3.651Z"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>
                          Task management system and work flow
                        </MDBCardTitle>
                        <MDBCardText>
                          Efficiently manage tasks, streamline workflows, and
                          improve team collaboration with our intuitive task
                          management system.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col lg={4} md={6} xs={12} className="proMarginTop">
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38.5"
                          height="35"
                          viewBox="0 0 38.5 35"
                        >
                          <path
                            id="cogs-f"
                            d="M24.5,10a2.579,2.579,0,0,0,0,4.5l-.878,2.947A2.578,2.578,0,0,0,20.9,21.192l-2.525,1.963A2.578,2.578,0,0,0,14.25,24.5l-4.032-.011A2.578,2.578,0,0,0,6.1,23.151L3.579,21.171a2.578,2.578,0,0,0-2.7-3.727L0,14.486a2.579,2.579,0,0,0,1.293-2.236A2.579,2.579,0,0,0,0,10.015L.875,7.058a2.578,2.578,0,0,0,2.7-3.727L6.1,1.347A2.578,2.578,0,0,0,8.525,1.8,2.578,2.578,0,0,0,10.22.009L14.25,0a2.578,2.578,0,0,0,1.7,1.8,2.578,2.578,0,0,0,2.431-.456L20.9,3.307a2.578,2.578,0,0,0,2.718,3.745ZM12.25,17.5A5.25,5.25,0,1,0,7,12.25a5.25,5.25,0,0,0,5.25,5.25Zm12.938.107a13.958,13.958,0,0,0,.954-7.1l2.11-.005a2.578,2.578,0,0,0,4.126,1.344l2.527,1.963a2.578,2.578,0,0,0,2.718,3.745L38.5,20.5a2.579,2.579,0,0,0,0,4.5l-.878,2.947A2.578,2.578,0,0,0,34.9,31.692l-2.525,1.963A2.578,2.578,0,0,0,28.25,35l-4.032-.01A2.578,2.578,0,0,0,20.1,33.651l-2.523-1.979a2.578,2.578,0,0,0-2.7-3.727L14.329,26.1a13.937,13.937,0,0,0,6.687-2.929,5.25,5.25,0,1,0,4.172-5.56Z"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>
                        Warehouse System and Production Control
                        </MDBCardTitle>
                        <MDBCardText>
                          Optimize warehouse management and production control
                          processes by gaining real-time visibility into
                          inventory levels. This enhances supply chain
                          efficiency.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="2s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35.468"
                          height="21.183"
                          viewBox="0 0 35.468 21.183"
                        >
                          <g id="myspace" transform="translate(0 -0.027)">
                            <ellipse
                              id="Ellipse_15"
                              data-name="Ellipse 15"
                              cx="4.568"
                              cy="4.522"
                              rx="4.568"
                              ry="4.522"
                              transform="translate(0.459 4.12)"
                              fill="#111b33"
                            />
                            <path
                              id="Path_182"
                              data-name="Path 182"
                              d="M4.965,8.1A5.054,5.054,0,0,0,0,13.16v1.273a.535.535,0,0,0,.539.532H9.518a.537.537,0,0,0,.539-.532V13.08A5,5,0,0,0,4.967,8.1Z"
                              transform="translate(0 6.245)"
                              fill="#111b33"
                            />
                            <ellipse
                              id="Ellipse_16"
                              data-name="Ellipse 16"
                              cx="4.944"
                              cy="4.895"
                              rx="4.944"
                              ry="4.895"
                              transform="translate(11.656 2.715)"
                              fill="#111b33"
                            />
                            <path
                              id="Path_183"
                              data-name="Path 183"
                              d="M11.666,13.783a5.468,5.468,0,0,0-5.373,5.473v1.422a.532.532,0,0,0,.537.532h9.805a.537.537,0,0,0,.539-.532V19.171a5.416,5.416,0,0,0-5.508-5.388Zm12.7-2.543a5.636,5.636,0,0,0,5.662-5.607,5.663,5.663,0,0,0-11.325,0A5.636,5.636,0,0,0,24.369,11.24Zm0,1.463a6.2,6.2,0,0,0-6.232,6.171v1.8a.535.535,0,0,0,.539.532H30.062a.537.537,0,0,0,.539-.532V18.873a6.2,6.2,0,0,0-6.232-6.17Z"
                              transform="translate(4.867)"
                              fill="#111b33"
                            />
                          </g>
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>Training system</MDBCardTitle>
                        <MDBCardText>
                          Invest in the professional growth of your employees
                          with our comprehensive training system. It delivers
                          engaging online modules and measures performance.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="3s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23.04"
                          height="38.4"
                          viewBox="0 0 23.04 38.4"
                        >
                          <path
                            id="ticket-f"
                            d="M23.04,17.28V38.4H16.954a5.762,5.762,0,0,0-10.867,0H0V17.28H7.68a1.92,1.92,0,1,0,0-3.84H0V0H6.086A5.762,5.762,0,0,0,11.52,3.845,5.762,5.762,0,0,0,16.954,0H23.04V13.44H15.36a1.92,1.92,0,0,0,0,3.84Z"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>
                        Visitor Registration System for Events
                        </MDBCardTitle>
                        <MDBCardText>
                          Enhance the visitor registration process for events
                          with our user-friendly system. It captures attendee
                          information and creates a smooth check-in experience.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>

              <Col
                lg={4}
                md={6}
                xs={12}
                className="proMarginTop wow animate__slideInUp"
                data-wow-offset="100"
                data-wow-duration="3s"
              >
                <MDBCard>
                  <Row>
                    <Col lg={2} md={2} xs={2} className="text-left pt-4">
                      <div className="centerIconn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28.8"
                          height="28.8"
                          viewBox="0 0 28.8 28.8"
                        >
                          <path
                            id="rocket-f"
                            d="M11.254,25.312a10.7,10.7,0,0,1-1.063,1.236q-2.814,2.811-8.488,1.7Q.587,22.571,3.4,19.757A10.673,10.673,0,0,1,4.636,18.7a14.365,14.365,0,0,0-4.16-1.3,9.612,9.612,0,0,1,8.457-6.655,21.826,21.826,0,0,1,3.521-4.568C17.768.863,24.607-.911,27.733,2.215s1.352,9.966-3.961,15.278A21.826,21.826,0,0,1,19.2,21.015a9.612,9.612,0,0,1-6.655,8.457,14.365,14.365,0,0,0-1.3-4.16Zm9.686-16.3a1.6,1.6,0,1,0,0-2.265A1.6,1.6,0,0,0,20.94,9.008ZM8.491,21.456c-.174-.176-.458-.443-.848-.805A7.213,7.213,0,0,0,5.38,22.3c-.594.581-.8,1.557-.8,3.073a4.691,4.691,0,0,0,3.063-.81,6.512,6.512,0,0,0,1.665-2.161A5.56,5.56,0,0,0,8.491,21.456Z"
                            transform="translate(-0.476 -0.672)"
                            fill="#111b33"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col lg={10} md={10} xs={10}>
                      <MDBCardBody>
                        <MDBCardTitle>
                        Sales POS and Inventory Management
                        </MDBCardTitle>
                        <MDBCardText>
                          Streamline sales operations and efficiently manage
                          inventory with our sales point-of-sale (POS) and
                          inventory management system. This simplifies
                          transactions and provides valuable insights.
                        </MDBCardText>
                      </MDBCardBody>
                    </Col>
                  </Row>
                </MDBCard>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default OurProduct;

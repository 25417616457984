import { Container, Row, Col, Carousel, Button, Card } from "react-bootstrap";
import { useEffect } from "react";
import WOW from "wowjs";
import "animate.css";

const Blogs = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <Container>
      <div className="Blogs" id="blogs">
        <Row>
          <div
            className=" text-center wow animate__fadeIn"
            data-wow-offset="100"
            data-wow-duration="2s"
          >
            <h4>Technology Blogs </h4>
            <h5>
              {" "}
              Stay ahead with Autometa's captivating blogs, empowering <br></br>
              your business growth with valuable industry insights.
            </h5>
          </div>
        </Row>
        <div className="text">
          <Row>
            <Col
              lg={6}
              md={6}
              xs={12}
              className="wow animate__fadeInLeft"
              data-wow-offset="100"
              data-wow-duration="2s"
            >
              <img src={require("../assets/Blogs/blogs-04.png")} alt="logo" />
              <div
                className="body wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration="2s"
              >
                <p>
                  Discover the importance of responsive web design in today's
                  mobile-driven world. Learn how it impacts user
                  experience,search engine rankings, and conversions.
                </p>
              </div>
            </Col>

            <Col
              lg={6}
              md={6}
              xs={12}
              className="wow animate__fadeInLeft"
              data-wow-offset="100"
              data-wow-duration="2s"
            >
              <img
                src={require("../assets/Blogs/blogs-05.png")}
                width={630}
                height={360}
                alt="logo"
              />
              <div
                className="body wow animate__fadeInLeft"
                data-wow-offset="100"
                data-wow-duration="2s"
              >
                
                <p>
                Explore how mobile applications have revolutionized various
                industries, such as e-commerce, healthcare, and finance.
                See how they unlock new opportunities for businesses.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Blogs;

import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFoundPage from "./pages/NotFoundPage";

import "./App.css";
// Default theme
import "@splidejs/react-splide/css";
import "swiper/css";
import "animate.css";
import HomePage from "./pages/HomePage";
import GetStart from "./pages/GetStart";
function App() {
 
  return (
    <BrowserRouter>
      <div className="App">
        <div id="page-body">
          <Routes>
            <Route path='/' element={<HomePage/>} />
            <Route path='/contactus' element={<GetStart/>} />
            <Route path='*' element={<NotFoundPage/>} />
          </Routes>
        </div>
        
      </div>
    </BrowserRouter>
  );
}

export default App;
